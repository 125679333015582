<template>
  <div v-if="productsVO">
    <div class="product-wrap">
      <div class="product-pic"  @click="toggleShow">
        <video  id="player-container-id"
                v-if="videoFileId"
                playsinline
                webkit-playinline
                x5-playinline
                x-webkit-airplay="true" webkit-playsinline="true"

        ></video>
        <img :src="productsVO.pic"   v-if="shopShow" id="showPic" ref="showPic" >
      </div>
      <div class="product-catno">WY{{productsVO.id}}</div>
      <div class="product-info">
        <div class="product-name">{{ productsVO.enname }}</div>
<!--        <div class="product-price" v-if="productPrice && biaowuPriceOpen && biaowuPriceOpen == '1' && isLogin" >${{productPrice}}</div>-->
        <div class="product-price">\</div>
      </div>
      <div class="product-detail" v-if="productLotList&&productLotList.length>1">
        <div class="title">Pack Size</div>
        <div class="product-guige">
          <span  class="product-specifications" v-for="(item, index) in productLotList" :key="index"  :class="item.specChecked?'product-active':''"  @click="clickSpec(item, index)">{{item.spec}}<template v-if="item.nongdu">,</template>{{item.nongdu}}</span>
        </div>
      </div>
      <div class="product-detail" v-else>
        <div class="title">Pack Size</div>
        <div class="product-guige">
          <span  class="product-specifications product-active">{{productsVO.specification}} , {{productsVO.nongdu}}</span>
        </div>
      </div>
      <div class="clear"></div>
      <div class="product-detail">
        <div class="title">Commodity Details</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead>
              <tr>
                <th colspan="2">Essential information</th>
              </tr>
            </thead>
            <tbody>
            <tr v-if="productsVO.shelfLife && productsVO.shelfLife!=0">
              <td class="name">Term of validity</td>
              <td>{{productsVO.shelfLife}}Months</td>
            </tr>
              <tr v-if="productsVO.enname">
                <td class="name">Name</td>
                <td>{{ productsVO.enname }}</td>
              </tr>

              <tr v-if="productsVO.casno">
                <td class="name">CAS</td>
                <td>{{ productsVO.casno }}</td>
              </tr>
              <tr v-if="productsVO.purity">
                <td class="name">Purity</td>
                <td>{{ productsVO.purity }}</td>
              </tr>
              <tr v-if="productsVO.batch">
                <td class="name">Batch</td>
                <td>{{ productsVO.batch }}</td>
              </tr>

              <tr v-if="productsVO.statu">
                <td class="name">Form</td>
                <td>{{ productsVO.statu }}</td>
              </tr>

              <tr v-if="productsVO.stroma && productsVO.anclassId != 10">
                <td class="name">Stroma</td>
                <td>{{ productsVO.stroma }}</td>
              </tr>

              <tr v-if="productsVO.storageCondition">
                <td class="name">Storage conditions</td>
                <td>{{ productsVO.storageCondition }}</td>
              </tr>

              <tr v-if="productsVO.applications">
                <td class="name">Applications</td>
                <td>{{ productsVO.applications }}</td>
              </tr>
              <tr v-else-if="productsVO.usedfor">
                <td class="name">Purpose</td>
                <td>{{ productsVO.usedfor }}</td>
              </tr>
              <tr v-if="productsVO.packing">
                <td class="name">Packing</td>
                <td>{{ productsVO.packing }}</td>
              </tr>

              <tr v-if="productsVO.notice">
                <td class="name">Matters needing attention</td>
                <td>{{ productsVO.notice }}</td>
              </tr>

              <tr v-if="productsVO.reference">
                <td class="name">Reference material</td>
                <td>{{ productsVO.reference }}</td>
              </tr>
              <tr v-if="productsVO.physicochemicalProperty">
                <td class="name">Physical and chemical properties</td>
                <td>{{ productsVO.physicochemicalProperty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="title title-top" v-if="productsVO.anclassId!=10">Magnitude information</div>
        <div class="content" v-if="productsVO.anclassId!=10">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead style="background-color: #5f5f5f; border-color: #5f5f5f">
              <tr>
                <th colspan="3">Magnitude information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="name">Component</td>
                <td>Concentration</td>
                <td>Uncertainty</td>
              </tr>
              <tr v-for="item in list" :key="item">
                <td class="name">{{ item.components }}</td>
                <td>{{ item.concentration }}</td>
                <td>{{ item.uncertainty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="other-view" v-if="productsVO.suppliercode == 1 && productsVO.anclassId!=10">
        <div class="title">Certificate details:</div>
        <div class="content">
          <div class="detail-title">1. Sample preparation</div>
          <div class="detail-content" v-if="productsVO.preparation">
            {{ productsVO.preparation }}
          </div>
          <!--稀释后方可使用-->
          <template
            v-if="
              productsVO.traceability &&
              productsVO.traceability.indexOf('should be diluted') != -1
            "
          >
            <div class="detail-title">2. Dilution method</div>
            <div class="detail-content">{{ productsVO.traceability }}}</div>
            <div class="detail-title">3. Characteristic value and expanded uncertainty after dilution</div>
          </template>
          <template v-else>
            <div class="detail-title">2. Traceability and valuation method</div>
            <div class="detail-content">{{ productsVO.traceability }}</div>
            <div class="detail-title">3. Eigenvalue and expanded uncertainty</div>
          </template>
          <table border="1" cellspacing="0" cellpadding="10.013px" v-if="comp">
            <thead>
              <tr>
                <th class="detail-name">Component</th>
                <th>Concentration</th>
                <th>Uncertainty</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="detail-name">{{ comp.components }}</td>
                <td>{{ comp.concentration }}</td>
                <td>{{ comp.uncertainty }}</td>
              </tr>
              <tr v-for="item in secListComp" :key="item">
                <td class="detail-name">{{ item.components }}</td>
                <td>{{ item.concentration }}</td>
                <td>{{ item.uncertainty }}</td>
              </tr>
            </tbody>
          </table>
          <div class="detail-content">
            <span v-if="productsVO.uncertaintySource">
              Expanded uncertainty of standard value{{ productsVO.uncertaintySource }}
            </span>
            <span v-else>
              The expanded uncertainty of the standard value is mainly synthesized by the uncertainty introduced by the purity and constant volume of raw materials、Synthesis of uncertainty introduced by constant volume, etc
            </span>
          </div>
          <div class="detail-title">4. Uniformity test and stability inspection</div>
          <div class="detail-content">
            The quality value of this standard is valid from the date of setting
            {{productsVO.shelfLife }}month,The development unit will continue to track and monitor the stability of the reference material, and will notify the user in time if there is any change in the value within the validity period.
          </div>
        </div>
      </div>
      <div class="recommend-title">Related Products</div>
      <div class="recommend">
        <div class="content">
          <ul>
            <li v-for="item in RelatedProIdList" :key="item">
              <router-link
                :to="{ name: 'productdetail', params: { gid: item.gid } }"
                push
                >{{ item.enname }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <slot-footer :pid="productsVO.gid" tid="0" nclassId="0" type="1" :isFav="isFav" :lotid=lotid :tcid=0 :stock="productsVO.stock"></slot-footer>
  </div>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import {defineComponent, ref, reactive, watch, inject, onMounted, nextTick, onUnmounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import axios from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import vTCPlayer from "@/hooks/useTcplayer";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";


export default defineComponent({
  name: "productdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
    const shareTitle = ref('')
    const route = useRoute();
    const router = useRouter();
    const gid = ref(route.params.gid);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const productsVO = ref(null);
    const list = ref([]);
    const secListComp = ref([]);
    const comp = ref({});
    const RelatedProIdList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);
    const  productLotList = ref()
    const lotid = ref()
    const tcid = ref(0)
    const specChecked = ref(true)
    const productPrice = ref()
    const productStock = ref()
    const biaowuPriceOpen = ref(0)
    const productPdfHref = ref()
    const isLogin = ref();
    const token = localStorage.getItem('token') || '';
    if(!token){
      isLogin.value = false;
    }else{
      isLogin.value = true;
    }
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    let VideoPlayer: any = null;
    const videoFileId = ref();
    const shopShow = ref(true)
    function getData(gid: any) {
      productsVO.value = null;
      api
        .get("/M/Product/ProductDetail/" + gid)
        .then((res): void => {

          if(res.data.success){

            const data = res.data.obj;
            biaowuPriceOpen.value = data.biaowuPriceOpen
            // if (data.productsVO.nongdu && data.productsVO.nongdu.indexOf('(') != -1) {
            //   const index = data.productsVO.nongdu.indexOf('(')
            //   data.productsVO.nongdu = data.productsVO.nongdu.substring(0, index)
            // }
            productsVO.value = data.productsVO;
            productPdfHref.value = data.productPdfHref
            /*document.title:浏览器title*/
            document.title = data.productsVO.enname + " | Weiye";
            shareTitle.value = data.productsVO.enname
            route.meta.title = data.productsVO.enname
            list.value = data.listComp;
            comp.value = data.comp;
            isFav.value = data.isFav;
            RelatedProIdList.value = data.RelatedProIdList;
            secListComp.value = data.secListComp;
            listCompLen.value = data.listComp.length;
            data.productLotList.length>1 && data.productLotList.forEach((item: any) => {
              item.specChecked = false
            })
            if(data.productLotList.length>1){
              data.productLotList[0].specChecked = true;
              lotid.value = data.productLotList[0].lotid
            }
            productLotList.value = data.productLotList
            const isPc = navigatorType();
            if (isPc) {
              window.location.href = `https://www.weiyel.com/p_${data.productsVO.classId}/p_${data.productsVO.gid}.html`;
            }
            if(data.productVideoId){
              videoFileId.value =data.productVideoId;
            }else{
              videoFileId.value ="5285890814547790536";
            }
            nextTick(() => {
              asyncLoadJs(
                  "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                  "ProductDetail"
              ).then(() => {
                asyncLoadJs(
                    "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                    "ProductDetail"
                ).then((res) => {
                  VideoPlayer = vTCPlayer(
                      "player-container-id",
                      videoFileId.value,
                      false
                  );
                  VideoPlayer.one('play',function () {
                    shopShow.value = false;
                  });
                });
              });
            })

            // 判断价格
            if (productLotList.value.length>1) {
              productStock.value = data.productLotList[0].stock
              productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice))
            }else{
              productStock.value = (productsVO.value as any).stock
              productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
            }

            /**
             *  存足迹
             */
            setFootItem({
              type: "product",
              img: (productsVO.value as any).switchPic,
              title: (productsVO.value as any).cnname,
              info: (productsVO.value as any).catno,
              proid: (productsVO.value as any).id,
              brand: (productsVO.value as any).suppliercode
                  ? "Standard value" + (productsVO.value as any).suppliercode + "Components"
                  : (productsVO.value as any).suppliercode,
              specification: (productsVO.value as any).specification,
              concentration: (productsVO.value as any).concentration,
              url: { name: "productdetail", params: { gid: route.query.gid } },
            });
          }else {
            Modal({
              title: "error",
              content: res.data.msg,
              optionControl:2,
              confirmText:"OK",
              onConfirm: () => {

                router.push({
                  name: "product",
                });
              },
            });
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(gid.value);

    function clickSpec(item: any, index: any) {
      productStock.value = item.stock
      lotid.value = item.lotid;

      productLotList.value.length>1 && productLotList.value.forEach((item: any) => {
        item.specChecked = false
      })
      productLotList.value[index].specChecked = true
      specChecked.value = false
      productPrice.value = Math.ceil(Number(item.outPrice))

    }
    function firstSpec() {
      if(!productLotList.value[0]) {
        return false
      }
      lotid.value = productLotList.value[0].lotid
      specChecked.value = true
      productLotList.value.length>0 && productLotList.value.forEach((item: any) => {
        item.specChecked = false
      })
    }
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        // console.log(to, from);
        if (to[1].gid && !from[1].valid && !from[1].validate) {
          // VideoPlayer.dispose()
          videoFileId.value = "";
          getData(to[1].gid as string);
        }
      }
    );
    onUnmounted(() => {
      // VideoPlayer.dispose()
      videoFileId.value = "";
    })
    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      productsVO,
      list,
      comp,
      gid,
      classId,
      isFav,
      RelatedProIdList,
      loadOption,
      secListComp,
      listCompLen,
      productLotList,
      clickSpec,
      specChecked,
      firstSpec,
      lotid,
      tcid,
      productPrice,
      biaowuPriceOpen,
      productPdfHref,
      productStock,
      videoFileId,
      isLogin,
      shopShow
    };
  },
});

</script>

<style>
#player-container-id{
  width: 375px;
  height: 375px;
}
.wrong-content p {
  text-indent: 10px;
  margin-top: 10px;
}

.wrong-content table {
  width: 355.013px;
  border: none;
  margin: 0 auto;
  /*margin-top: 19.988px;*/
  border-collapse: collapse;
  border-color: #ccc;
}

.wrong-content thead {
  width: 355.013px;
  background-color: #df0024;
  border: 1px solid #df0024;
}

.wrong-content th {
  color: #fff;
  text-align: left;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 13.988px;
}
.wrong-content td {
  font-size: 13.988px;
  color: #666666;
  padding: 10.013px;
  word-break: break-word;
}
.wrong-content h3{
  padding: 0.02rem 0;
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
video {
  width: 100%;
}
.product-wrap {
  padding-bottom: 50px;
}
.title-top {
  margin-top: 40px;
}
.product-pic{
  //width:375px;
  //height: 375px;
  border:1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.product-pic img {
  //width: 85%;
  //display: block;
  //position: absolute;
  //top: 3rem;
  //left:1rem
  width:375px;
  //height: 375px;
  margin-left: -10rem;
}

.product-catno {
  padding: 0 10.013px;
  padding-top: 21.487px;
  color: #444444;
  font-size: 16px;
  position: relative;
  font-weight: bold;
}

.product-info {
  position: relative;
  padding: 10.013px;
  padding-top: 3px;
  // padding-top: 21.487px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 6px solid #f4f4f4;

  display: flex;
  justify-content: space-between;
}

.product-param {
  padding: 10px;
  padding-bottom: 0;
  padding-left: 0.35rem;
  font-size: 14px;
  color: #666;
  display: flex;
  flex-wrap: wrap;
}

.highlight {
  background: #ffeded;
  padding: 0 10px;
  // border: 0.02667rem solid red;
  border: 1px solid #ffeded;
  background: #ffeded;
  color: red;
}

.product-param>div {
  width: 50%;
  line-height: 0.55rem;
  padding: 0.08rem 0;
}

.product-guige{
  // min-height: 2.5rem;
  // height: auto;
  display: flex;
  flex-wrap: wrap;
  span {
    height: auto;
    word-break: break-all;
    word-wrap: break-word;
    line-height: 0.55rem;
    padding: 3px 0;
  }
}
.product-specifications{
  display: inline-block;
  width: 45%;
  font-size: 14px;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  margin-left: 0.35rem;
  border:1px solid #ddd;
  margin-bottom:0.3rem;
  float:left;
  color: #666666;
}
.product-active{
  /*border: 1px solid red;*/
  /*background: #ffeded;*/
  /*color: red;*/
  border:1px solid #df0024 !important;
  background:url("../assets/img/xuanzhong.png") no-repeat  right 100%;
}
.product-name {
  width: 255px;
  color: #444444;
  font-size: 16.012px;
  position: relative;
  font-weight: bold;
}

.product-price {
  font-size: 14px;
  // margin-top: 10px;
  color: #DF0024;
  width: 75px;
  align-self: flex-end;
  font-weight: bold;
  text-align: center;
}

.operation {
  position: absolute;
  right: 10.013px;
  top: 50%;
  transform: translateY(-50%);
}
.operation a {
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}

.product-detail .title,
.recommend-title {
  padding-left: 19.988px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 43.988px;
  line-height: 43.988px;
}

.recommend-title {
  margin-top: 0.5rem;
}
.product-detail .title::after,
.recommend-title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10.013px;
  top: 50%;
  height: 13.012px;
  background-color: #df0024;
  margin-top: -6.487px;
}

table {
  width: 355.013px;
  border: none;
  margin: 0 auto;
  /*margin-top: 19.988px;*/
  border-collapse: collapse;
  border-color: #ccc;
}
thead {
  width: 355.013px;
  background-color: #df0024;
  border: 1px solid #df0024;
}
th {
  color: #fff;
  text-align: left;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 13.988px;
}
td {
  font-size: 13.988px;
  color: #666666;
  padding: 10.013px;
  word-break: break-word;
}

.name {
  width: 117px;
  box-sizing: border-box;
  border-right-color: #e5e5e5;
  background-color: #f2f2f2;
  line-height: 25.013px;
}
.detail-name {
  // width: 49.987px;
  box-sizing: border-box;
}
.wrong-view {
  padding: 0 10px;
  color: #666;
  font-size: 14px;
  margin-top: 10px;
}

.wrong-view .title{
  padding-left: 19.988px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 43.988px;
  line-height: 43.988px;
}

.wrong-view .title::after{
  content: "";
  position: absolute;
  width: 2px;
  left: 10.013px;
  top: 50%;
  height: 13.012px;
  background-color: #df0024;
  margin-top: -6.487px;
}

.other-view {
  margin-top: 19.988px;
  border-top: 1px solid #eee;
}
.other-view .title {
  text-align: justify;
  font-size: 16.012px;
  color: #666666;
  line-height: 25.013px;
  margin: 19.988px 10.013px;
  font-weight: bold;
}

.other-view .content .detail-title {
  width: 355.013px;
  margin: 0 auto;
  font-size: 16.012px;
  color: #444;
  position: relative;

  margin-top: 19.988px;
  box-sizing: border-box;
}

.other-view .content .detail-content {
  width: 355.013px;
  margin: 0 auto;
  font-size: 15px;
  color: #444;
  position: relative;

  box-sizing: border-box;
}

.pdt_detail_box {
  font-size: 16.012px;

  color: #444;
  width: 375px;
  box-sizing: border-box;
  width: 355.013px;
  margin: 0 auto;
}

.info-left {
  margin: 0 auto;
  text-align: center;
}

.productViews {
  width: 109.987px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #df0024;
  color: #fff;
  border-radius: 36px;
  margin: 0 auto;
  margin-top: 7.987px;
}

.other-view .content a {
  text-align: justify;
  font-size: 16.012px;
  line-height: 25.013px;
  display: block;
  color: #666666 !important;
  margin: 12.488px 10.013px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recommend {
  width: 355.013px;
  text-align: left;
  margin: 0 auto;

  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.recommend .title {
  padding-left: 0.53301rem;
  font-size: 0.4rem;
  color: #444;
  position: relative;
  box-sizing: border-box;
  height: 1.17301rem;
  line-height: 1.17301rem;
  border-bottom: 0.02667rem solid #f4f4f4;
  margin-top: 0.5rem;
}
.recommend .title::after {
  content: "";
  position: absolute;
  width: 0.05333rem;
  left: 0.26701rem;
  top: 50%;
  height: 0.34699rem;
  background-color: #df0024;
  margin-top: -0.17299rem;
}
.recommend li {
  position: relative;
}

.recommend li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 10.013px;
  width: 4.988px;
  height: 4.988px;
  background: #7f7f7f;
  border-radius: 50%;
}

.recommend a {
  text-align: justify;
  font-size: 13.988px;
  line-height: 25.013px;
  display: block;
  color: #666666;
  margin: 10.013px 0;
  overflow: hidden;
  padding-left: 10.013px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recommend h3 {
  margin: 0;
  font-weight: normal;
}

.recommend-title {
  padding-left: 19.988px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 43.988px;
  line-height: 43.988px;
  border-bottom: 1px solid #f4f4f4;
}

.recommend-title {
  margin-top: 0.5rem;
}
.recommend-title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10.013px;
  top: 50%;
  height: 13.012px;
  background-color: #df0024;
  margin-top: -6.487px;
}
</style>
